<template>
  <div
    v-if="mode === 'template' && !element.image"
    class="w-full mb-3 p-4 border rounded d-flex justify-content-center"
  >
    <div>
      <h5>{{ $t("models.report_component.titles.image") }}</h5>

      <be-skeleton-img aspect-ratio="1:1" width="300px" />
    </div>
  </div>

  <div v-else class="mb-3 d-flex flex-column justify-content-center">
    <be-img
      v-if="element.image"
      :src="imageElementUrl(element)"
      :alt="element.image.metadata.filename"
      class="align-self-center"
    />

    <comments-viewer v-if="element.comments" :comments="element.comments" />
  </div>
</template>

<script>
import CommentsViewer from "./CommentsViewer.vue";
import ReportMixin from "@/mixins/reports";
import { EventBus } from "@/event-bus";

export default {
  components: {
    CommentsViewer,
  },

  mixins: [ReportMixin],

  props: {
    element: {
      type: Object,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },
  },

  mounted() {
    EventBus.emit("report-component-initialized", this.element);
  },
};
</script>

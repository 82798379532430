<template>
  <div class="mb-3">
    <div class="row">
      <div
        v-for="column in columns"
        :key="`columns-${column.id}`"
        :class="columnClass(column)"
      >
        <component
          :is="reportElementComponent(child.component_type)"
          v-for="child in children(column.id)"
          :key="`${column.id}-${child.id}`"
          :element="child"
          :report="report"
          :force-show="forceShow"
          :mode="mode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

import ReportMixin from "@/mixins/reports";

import FinancialElement from "./FinancialElementViewer.vue";
import ImageElement from "./ImageElementViewer.vue";
import SpaceElement from "./SpaceElementViewer.vue";
import TextElement from "./TextElementViewer.vue";
import TiptapTextElement from "./TiptapTextElementViewer.vue";
import TiptapTitleElement from "./TiptapTitleElementViewer.vue";
import TitleElement from "./TitleElementViewer.vue";

export default {
  components: {
    FinancialElement,
    ImageElement,
    SpaceElement,
    TextElement,
    TiptapTextElement,
    TiptapTitleElement,
    TitleElement,
  },

  mixins: [ReportMixin],

  props: {
    element: {
      type: Object,
      required: true,
    },

    report: {
      type: Object,
      required: true,
    },

    elementsByParent: {
      type: Object,
      required: true,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },
  },

  computed: {
    columns() {
      return this.children(this.element.id);
    },
  },

  mounted() {
    // If a column has index >= number_of_columns, its elements will not be rendered
    // This is to avoid waiting for them in pdf generation
    this.columns.forEach((column, index) => {
      if (index >= this.element.number_of_columns) {
        this.children(column.id).forEach((element) => {
          EventBus.emit("report-component-initialized", {
            ...element,
            skipped: "extra_columns",
          });
        });
      }
    });

    EventBus.emit("report-component-initialized", this.element);
  },

  methods: {
    children(columnId) {
      return this.elementsByParent[columnId] || [];
    },

    columnClass(column) {
      return `col-12 col-md-${column.number_of_columns} col-print-${column.number_of_columns}`;
    },
  },
};
</script>

<template>
  <div
    class="mb-3"
    :class="{
      'print-visualby-table': element.component_type === 'report_table',
      'page-break-avoid': element.component_type !== 'report_table',
    }"
  >
    <div
      v-if="
        mode === 'template' &&
        !element.description &&
        !element.financial_configuration
      "
      class="mb-3 p-4 border rounded"
    >
      <h5>
        {{ $t(`models.report_component.titles.${element.component_type}`) }}
      </h5>

      <be-skeleton-table
        v-if="element.component_type === 'report_table'"
        :rows="3"
        :columns="1"
        :table-props="{ striped: true }"
      />

      <be-skeleton-graph v-else />
    </div>

    <div v-else-if="financialsInitialized" :class="wrapperClasses">
      <financials-visual
        :key="visualKey"
        :class="financialsClasses"
        :configuration="element.description"
        :configuration-object="element.financial_configuration"
        :force-origin="elementOrigin(element, report)"
        :force-end-date-string="reportEndDateString(report)"
        :forced-financials-uuid="reportFinancialsUuid(report)"
        :force-show="forceShow"
        @financials-initialized="handleFinancialsInitialized"
      />
    </div>

    <div
      v-else-if="!initializingFinancials"
      class="d-flex flex-column border rounded text-center p-3 mb-3"
    >
      <h5>
        <i class="far fa-table mr-2" />
        {{ $t("models.report_component.tooltips.financials_not_loaded") }}
      </h5>

      <be-skeleton-table
        v-if="element.component_type === 'report_table'"
        :rows="3"
        :columns="1"
        :table-props="{ striped: true }"
      />

      <be-skeleton-graph v-else />
    </div>

    <comments-viewer v-if="element.comments" :comments="element.comments" />
  </div>
</template>

<script>
import CommentsViewer from "./CommentsViewer.vue";
import ReportMixin from "@/mixins/reports";
import FinancialsVisual from "@/components/financials/FinancialsVisual.vue";
import { EventBus } from "@/event-bus";

export default {
  components: {
    CommentsViewer,
    FinancialsVisual,
  },

  mixins: [ReportMixin],

  inject: ["accentColor"],

  props: {
    element: {
      type: Object,
      required: true,
    },

    report: {
      type: Object,
      required: true,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },
  },

  data() {
    return {
      visualKey: this.generateUuid(),
    };
  },

  computed: {
    isCorporateGroupReport() {
      return (
        this.report.report_type === "corporate_group" ||
        this.report.corporate_group_data
      );
    },

    wrapperClasses() {
      switch (this.element.component_type) {
        case "report_table":
          return "table-responsive";
        case "widget":
          return this.mode === "report"
            ? "financials-widget-wrapper financials-report-widget"
            : "financials-widget-wrapper";
        default:
          return "";
      }
    },

    financialsClasses() {
      if (
        this.flipperFlag("report_accent_color") &&
        this.element.component_type === "widget" &&
        this.accentColor
      ) {
        return "accent-color";
      } else {
        return "";
      }
    },
  },

  watch: {
    "getFinancials.drillDown": {
      handler() {
        this.visualKey = this.generateUuid();
      },
    },
  },

  mounted() {
    // Customer invoices tables begin with `ci_` and they have no callback
    if (
      !this.element.has_financial_configuration ||
      (this.element.component_type === "report_table" &&
        this.element.description?.startsWith("ci_"))
    ) {
      EventBus.emit("report-component-initialized", {
        ...this.element,
        skipped: "incorrect_financial_configuration",
      });
    }
  },

  methods: {
    handleFinancialsInitialized() {
      EventBus.emit("report-component-initialized", this.element);
    },
  },
};
</script>
